import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import styled, {css} from 'styled-components'
import {Flex, Span} from './UI'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'
import {breakpoints} from '../utils/helper'
import useDocumentScrollThrottled from '../utils/useDocumentScrollThrottled'
import _ from 'lodash'
import Disclaimer from "./Disclaimer";
import sint from '../images/kleurplaat.jpg'
import Button from './Button';
import Cross from '../svg/cross.svg';
import {OutboundLink} from 'gatsby-plugin-gtag';
import Ticker, { NewsTicker } from 'nice-react-ticker';

const Container = styled(Flex)`
    z-index: 1000;
    width: 100%;
    height: 64px;
    background-color: #fff;
    align-items: center;
    padding: 0 18px;
    position: fixed;
    top: 0;
    transform: translateY(0);
    transition: all 0.75s ease;
    background-color: ${props => !props.dark ? props.theme.colors.white : props.theme.colors.black};
    
    ${props => props.hasDisclaimer && css`
        position: relative;
        margin-bottom: -90px; 
    `}
    
    &.hidden {
        transform: translateY(-100%);
    }
    
    &.shadow {
        background-color: ${props => !props.dark ? props.theme.colors.white : props.theme.colors.black};
    }
    
    @media ${breakpoints.desktop} {
        height: 90px;
    }
    
    .mobile-menu {
        display: flex;
        
        @media ${breakpoints.desktop} {
            display: none;
        }
    }
    
    .desktop-menu {
        display: none;
        
        @media ${breakpoints.desktop} {
            display: flex;
        }
    }
`

const DisclaimerBox = styled(Flex)`
    flex-direction: column;
  width: 100%;
    justify-content: space-between;
  align-items: center;

  @media ${breakpoints.desktop} {
    flex-direction: row;
  }
  
  img {
    max-width: 400px;
    width: 100%;
  }
`

const HeaderSpan = styled(Span)`
    margin-top: 20px;
    font-size: 1.125rem;
`

const HeaderBox = styled(Flex)`
    flex-direction: column;
    max-width: 800px;
    width: 100%;
    margin-bottom:40px;

  @media ${breakpoints.desktop} {
    margin-bottom:0px;
  }
`


const Header = ({siteTitle, location, disclaimer}) => {
    const [shouldHideHeader, setShouldHideHeader] = useState(false);
    const [shouldShowShadow, setShouldShowShadow] = useState(false);
    const [shouldDisclaimer, setShouldDisclaimer] = useState(false);

    const MINIMUM_SCROLL = 100;
    const TIMEOUT_DELAY = 0;

    useDocumentScrollThrottled(callbackData => {
        const {previousScrollTop, currentScrollTop} = callbackData;
        const isScrolledDown = previousScrollTop < currentScrollTop;
        const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

        setShouldShowShadow(currentScrollTop > 64);

        setTimeout(() => {
            setShouldHideHeader(isScrolledDown && isMinimumScrolled);
        }, TIMEOUT_DELAY);
    });

    useEffect(() => {
        setShouldDisclaimer(false)//disclaimer.show)
    }, [disclaimer.show])

    const shadowStyle = shouldShowShadow ? 'shadow' : '';
    const hiddenStyle = shouldHideHeader ? 'hidden' : '';

    return (
        <>
            {shouldDisclaimer &&
                <Disclaimer
                    title="Beverwijk gesloten"
                    toggle={disclaimer.toggle}
                >
                    <DisclaimerBox>
                        <HeaderBox>
                            <HeaderSpan>{`Onze locatie in Beverwijk is vandaag gesloten vanwege een technische storing. ` +
                                `Wij verontschuldigen ons voor het ongemak en werken aan een snelle oplossing. Voor dringende vragen kun je contact opnemen met onze klantenservice.`}
                            </HeaderSpan>
                            {/*<OutboundLink href="https://media.upcarwash.nl/downloads/kleurplaat.pdf" target="_blank">*/}
                            {/*    <Button color={Button.styles.RED} inverse>*/}
                            {/*        Claim je kleurplaat*/}
                            {/*    </Button>*/}
                            {/*</OutboundLink>*/}
                        </HeaderBox>

                        {/*<img src={sint}/>*/}
                    </DisclaimerBox>

                </Disclaimer>}

            {/*<div>*/}
            {/*    <div className="absolute">*/}
            {/*        <Ticker isNewsTicker={true} slideSpeed={20}>*/}
            {/*            <NewsTicker id="1" meta="" title="Onze excuses voor indien meerdere mails van ons heeft ontvangen" url="" />*/}
            {/*            <NewsTicker id="2" meta="" title="Wint jouw kleine artiest deze prijs ter waarde van €25,-? Lever 'm gekleurd in bij één van de UP-vestigingen en maak kans!" url=" https://media.upcarwash.nl/downloads/kleurplaat.jpg" />*/}
            {/*            <NewsTicker id="3" meta="" title="Onze excuses voor indien meerdere mails van ons heeft ontvangen" url="" />*/}
            {/*            <NewsTicker id="4" meta="" title="Wint jouw kleine artiest deze prijs ter waarde van €25,-? Lever 'm gekleurd in bij één van de UP-vestigingen en maak kans!" url=" https://media.upcarwash.nl/downloads/kleurplaat.jpg" />*/}
            {/*            <NewsTicker id="5" meta="" title="Onze excuses voor indien meerdere mails van ons heeft ontvangen" url="" />*/}
            {/*        </Ticker>*/}
            {/*    </div>*/}
                <Container className={`${shadowStyle} ${hiddenStyle}`} dark={_.includes(['/franchise', '/franchise/'], location.pathname)}>
                    <MobileMenu location={location} className="mobile-menu"/>
                    <DesktopMenu location={location} className="desktop-menu"/>
                </Container>
            {/*</div>*/}
        </>
    )
}

            Header.propTypes = {
            siteTitle: PropTypes.string,
        }

            Header.defaultProps = {
            siteTitle: ``,
        }

            export default Header
